import React, { useState } from 'react';
//import axios from 'axios';
import axiosModificado from 'components/ConfiguracionAxios';
import Swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';

function CrearUsuario() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    codigo: '',
    whatsapp: '',
    telefono: '',
    tienda: '',
    estado: '',
    tipo: '',
    fecha_nac: '',
    password: '',
  });

  const estado = ['Activo', 'Inactivo', 'Vacaciones', 'Incapacitado', 'Suspendido'];
  const tipo = ['Supervisor', 'Gerente', 'Jefe', 'Colaborador'];
  /* const tipos = ['Tipo1', 'Tipo2', 'Tipo3']; */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeTelefono = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/g, '').slice(0, 8);
    setFormData({ ...formData, [name]: formattedValue });
  };

  const extractEmailText = (inputText) => {
    const regex = /column\s+(\w+)/;
    const match = inputText.match(regex);
    if (match) {
      return match[1];
    }
    return '';
  };

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    // Aquí puedes enviar los datos a tu servidor o realizar cualquier otra acción necesaria.
    try {
      // Realizar la solicitud POST con Axios
      const response = await axiosModificado.post('/agregar_user', formData);

      // Manejar la respuesta si es necesario 
      console.log('Respuesta del servidor:', response.data);
      //const textoExtraido = extractEmailText(response.data);
      
      if( response.data == true ){

        Swal.fire({
          title: 'Usuario agregado',
          text: '',
          icon: 'success',
          confirmButtonText: 'Ir a Usuarios'
        }).then((result) => {
          history.push('/admin/usuarios');
        });
      } else {
        
        Swal.fire("Error", "Puede ser que el correo ya este agregado, revisa ls datos.", "error");

      }

    } catch (error) {
      // Manejar los errores
      console.error('Error al enviar datos:', error);
      Swal.fire("Error", "Error en guardar" , "error");
    }
  };

  return (
    <>
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className='px-4 md:px-10 mx-auto w-full'>
          <div className='p-4 bg-white rounded'>
            <h6 className="text-blueGray-500 text-sm font-bold">Crear Usuario</h6>
            <form onSubmit={handleSubmit}>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Nombre:
                </label>
                <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Correo:
                </label>
                <input type="text" name="email" value={formData.email} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Whatsapp:
                </label>
                <input type="text" name="whatsapp" value={formData.whatsapp} onChange={handleInputChangeTelefono} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Codigo de empleado:
                </label>
                <input type="text" name="codigo" value={formData.codigo} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Telefono:
                </label>
                <input type="text" name="telefono" value={formData.telefono} onChange={handleInputChangeTelefono} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Estado:
                </label>
                <select
                  name="estado"
                  value={formData.estado}
                  onChange={handleInputChange}
                >
                  <option value="">Selecciona un estado</option>
                  {estado.map((dept, index) => (
                    <option key={index} value={index}>
                      {dept}
                    </option>
                  ))}
                </select>
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Tipo:
                </label >
                <select
                  name="tipo"
                  value={formData.tipo}
                  onChange={handleInputChange}
                >
                  <option value="">Selecciona un tipo</option>
                  {tipo.map((muni, index) => (
                    <option key={index} value={index}>
                      {muni}
                    </option>
                  ))}
                </select>
              </div>

              
              
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Password:
                </label >
                <input type="text" name="password" value={formData.password} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Fecha de Nacimineto:
                </label >
                <input type="date" name="fecha_nac" value={formData.fecha_nac} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              
              <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
            </form>
          </div>
        </div> 
      </div>
    </>
  );
}

export default CrearUsuario;
