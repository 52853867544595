import React, { useEffect, useState } from "react";
import axiosModificado from "components/ConfiguracionAxios";
import { useUser } from "context/useUser";
import { useLogger } from "hooks/useLogger";

export default function ResetPassword() {
  const [formData, setFormData] = useState({ email: "", pass: "" });
  const [mensaje, setMensaje] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [codigo, setCodigo] = useState("");
  const [codigoIngresado, setCodigoIngresado] = useState("");
  const [nuevoPass, setNuevoPass] = useState("");
  const [validado, setValidado] = useState(false);
  
  const logger = useLogger();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await axiosModificado.post("/reset-password", formData);
    setLoading(false);
    const { codigo, mensaje, tipo } = response.data;
    setTipo(tipo);
    console.log("Tipo recibido:", tipo);
    setMensaje(mensaje);
    setColor(tipo == 2 ? "text-teal-600" : "text-red-500");
    if (tipo == 2) setCodigo(response.data.codigo); // Guardar el código enviado
  };

  useEffect(() => {
    console.log("Tipo actualizado: (useEffect)", tipo);
  }, [tipo]);

  const handleCodigoSubmit = (e) => {
    e.preventDefault();
    if (codigoIngresado === codigo) {
      setValidado(true);
      setMensaje("Código verificado. Ingresa tu nueva contraseña.");
      setColor("text-teal-500");
    } else {
      setMensaje("Código incorrecto, intenta nuevamente.");
      setColor("text-red-500");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await axiosModificado.post("/update-password", { email: formData.email, newPass: nuevoPass });
    setLoading(false);
    setMensaje(response.data.mensaje);
    setColor("text-teal-500");
  };

  return (
    <div className="container mx-auto px-4 h-screen flex justify-center items-center">
      <div className="w-full lg:w-4/12 px-4">
        <h3 className={color}>{mensaje}</h3>
        <div className="shadow-lg rounded-lg bg-blueGray-200 border-0 p-6">
          <h6 className="text-center text-blueGray-500 text-sm font-bold">Reestablecer Contraseña</h6>
          <form onSubmit={handleSubmit}>
            <input type="email" name="email" placeholder="Email" className="w-full p-2 border rounded" onChange={handleInputChange} required />
            <button type="submit" className="w-full bg-blueGray-800 text-white p-2 rounded mt-4">Enviar Código</button>
          </form>
          
        </div>

        {tipo == 2 && !validado && (
          <div className="shadow-lg rounded-lg bg-blueGray-200 border-0 p-6 mt-4">
            <h6 className="text-center text-blueGray-500 text-sm font-bold">Ingresar Código</h6>
            <form onSubmit={handleCodigoSubmit}>
              <input type="text" placeholder="Código recibido" className="w-full p-2 border rounded" onChange={(e) => setCodigoIngresado(e.target.value)} required />
              <button type="submit" className="w-full bg-blueGray-800 text-white p-2 rounded mt-4">Validar</button>
            </form>
          </div>
        )}

        {validado && (
          <div className="shadow-lg rounded-lg bg-blueGray-200 border-0 p-6 mt-4">
            <h6 className="text-center text-blueGray-500 text-sm font-bold">Nueva Contraseña</h6>
            <form onSubmit={handlePasswordSubmit}>
              <input type="password" placeholder="Nueva contraseña" className="w-full p-2 border rounded" onChange={(e) => setNuevoPass(e.target.value)} required />
              <button type="submit" className="w-full bg-blueGray-800 text-white p-2 rounded mt-4">Actualizar</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
