import React, { useEffect, useState } from "react";
import {Link } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";
import Select from 'react-select';

export default function EditarTienda() {
  const {id} = useParams();
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    direccion: '',
    departamento: '',
    municipio: '',
    telefono: '',
    supervisor: '',
    tipo: ''
  });
  const [selectedOptionUsuario, setSelectedOptionUsuario] = useState(null);
  const [selectedOptionTipo, setSelectedOptionTipo] = useState(null);
  const departamentos = [ "Ahuachapán", "Cabañas", "Chalatenango", "Cuscatlán", "La Libertad", "La Paz", "La Unión", "Morazán", "San Miguel", "San Salvador", "San Vicente", "Santa Ana", "Sonsonate", "Usulután" ];
  const municipios = [ "Ahuachapán Norte", "Ahuachapán Centro", "Ahuachapán Sur", "San Salvador Norte", "San Salvador Oeste", "San Salvador Este", "San Salvador Centro", "San Salvador Sur", "La Libertad Norte", "La Libertad Centro", "La Libertad Oeste", "La Libertad Este", "La Libertad Costa", "La Libertad Sur", "Chalatenango Norte", "Chalatenango Centro", "Chalatenango Sur", "Cuscatlán Norte", "Cuscatlán Sur", "Cabañas Este", "Cabañas Oeste", "La Paz Oeste", "La Paz Centro", "La Paz Este", "La Unión Norte", "La Unión Sur", "Usulután Norte", "Usulután Este", "Usulután Oeste", "Sonsonate Norte", "Sonsonate Centro", "Sonsonate Este", "Sonsonate Oeste", "Santa Ana Norte", "Santa Ana Centro", "Santa Ana Este", "Santa Ana Oeste", "San Vicente Norte", "San Vicente Sur", "San Miguel Norte", "San Miguel Centro", "San Miguel Oeste", "Morazán Norte", "Morazán Sur" ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get(`/tienda/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error al obtener el item', error);
      }
    };

    fetchData();
    
  }, [id]);  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeTelefono = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/g, '').slice(0, 8);
    setFormData({ ...formData, [name]: formattedValue });
  };

  const [tipo, setTipo] = useState([ {value: '1', label: 'Centro'}, {value: '2', label: 'Distribuidora'}, ]);
  useEffect(()=>{
    setSelectedOptionTipo({ value: '1', label: 'Centro' });
  }, [])
  const [usuario, setUsuarios] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_usuario_supervisores_for_select')
      .then( response => {
        setUsuarios(response.data);
        const opcionPreseleccionadaUsuario = response.data.find((option) => {
          console.log(formData.supervisor)
          if( option.value == formData.supervisor ){
            setSelectedOptionUsuario({ value: option.value , label: option.label });
          } 
        });
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      }); 
  }, [formData.supervisor]);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    try {
      const response = await axiosModificado.post('/actualizar_tienda', formData);
      if (response.data.length !== 0) {
        Swal.fire({
          title: 'Exito',
          text: 'Se actualizo correctamente',
          icon: 'success',
          confirmButtonText: 'Ir a tiendas'
        }).then((result) => {
          history.push('/admin/tiendas');
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'hubo un error al actualizar',
          icon: 'error',
          confirmButtonText: 'Intentar de nuevo'
        })
      }
      console.log('Respuesta del servidor:', response.data);
      //alert(response);
    } catch (error) {
      Swal('Error al actualizar', 'por favor, intente de nuevo', 'error');
      console.error('Error al enviar datos:', error);
    }
  };
  
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Editar Tienda" />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-6 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Editando: {formData.nombre}
                    </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    {/* <Link
                      className={
                        "bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      to="../tiendascrear"
                    >
                      Agregar
                    </Link> */}
                    
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='relative w-full mb-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Nombre:
                    </label>
                    <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Direccion:
                    </label>
                    <input type="text" name="direccion" value={formData.direccion} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Departamento:
                    </label>
                    <select
                      name="departamento"
                      value={formData.departamento}
                      onChange={handleInputChange}
                    >
                      <option value="">Selecciona un departamento</option>
                      {departamentos.map((dept, index) => (
                        <option key={index} value={index}>
                          {dept}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='relative w-full mb-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Municipio:
                    </label >
                    <select
                      name="municipio"
                      value={formData.municipio}
                      onChange={handleInputChange}
                    >
                      <option value="">Selecciona un municipio</option>
                      {municipios.map((muni, index) => (
                        <option key={index} value={index}>
                          {muni}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='relative w-full mb-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Telefono:
                    </label >
                    <input type="text" name="telefono" value={formData.telefono} onChange={handleInputChangeTelefono} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Supervisor:
                    </label >
                    <Select options={usuario} name='supervisor' value={selectedOptionUsuario} placeholder="Seleccione Usuario" onChange={(selectedOptionUsuario)=>{
                        setSelectedOptionUsuario(selectedOptionUsuario);
                        setFormData({ ...formData, supervisor: selectedOptionUsuario ? selectedOptionUsuario.value : '' });
                    }} />
                  </div>
                  
                  
                  <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Actualizar</button>
                </form>
                         
            </div>
          </div>
        </div>
        <div className="relative md:pt-2 pb-6 pt-2">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
                     
            </div>
          </div>
        </div>



        <div className="px-4 md:px-10 mx-auto w-full">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
