import Tiendas from "layouts/Tiendas";
import Rol from "layouts/Rol";
import TiendasTodas from "layouts/read/TiendasTodas";
import UsuariosTodos from "layouts/read/UsuariosTodos";
import Usuarios from "layouts/Usuarios";
import Calendario from "layouts/Calendario";
import EditarRol from "layouts/edit/EditRol";
import EditarTienda from "layouts/edit/EditTienda";
import EditarUsuario from "layouts/edit/EditUsuario";
import AdminDashboard from "views/admin/AdminDashboard";
import CalendarioColaborador from 'views/colaborador/CalendarioColaborador';
import { UsuarioColaborador } from 'views/colaborador/UsuarioColaborador';
import CrearHorario from 'views/admin/CrearHorario';
import EditHorarios from "layouts/edit/EditHorarios";
import Horarios from "views/admin/Horarios";
import Reporte1 from "layouts/reportes/Reporte1";
import Reporte2 from "layouts/reportes/Reporte2";
import Profile from "views/Profile";
import ProfileColaborador from "views/colaborador/ProfileColaborador";
import Facturas from "views/admin/Facturas";

export const protectedAdminRoutes = [
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/calendario", component: Calendario },
  { path: "/admin/tiendas/eliminar/:id", component: Tiendas },
  { path: "/admin/tiendas/editar/:id", component: EditarTienda },
  { path: "/admin/tienda/crear", component: Tiendas },
  { path: "/admin/tiendas", component: TiendasTodas },
  { path: "/admin/usuarios/eliminar/:id", component: Usuarios },
  { path: "/admin/usuarios/editar/:id", component: EditarUsuario },
  { path: "/admin/usuario/crear", component: Usuarios },
  { path: "/admin/usuarios", component: UsuariosTodos },
  { path: "/admin/horarios/editar/:id", component: EditHorarios },
  { path: "/admin/horarios", component: Horarios },
  { path: "/admin/horario/crear", component: CrearHorario },
  { path: "/admin/reporte1", component: Reporte1 },
  { path: "/admin/reporte2", component: Reporte2 },
  { path: "/admin/facturas", component: Facturas },
];

export const protectedCollaboratorRoutes = [
  { path: "/colaborador/calendario", component: CalendarioColaborador },
  { path: "/colaborador/usuario", component: UsuarioColaborador },
  { path: "/colaborador/profile", component: ProfileColaborador },
];

export const protectedUserRoutes = [
  
  { path: "/admin/rol/eliminar/:id", component: EditarRol },
  { path: "/admin/rol/editar/:id", component: EditarRol },
  { path: "/admin/rol", component: Rol }
];
